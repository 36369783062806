'use client'

import { routerQueryToQueryParams } from 'src/views/Videos/utils'
import { getReferrerInfo } from 'src/utils/browserUtils'

export const NavigationContext = React.createContext()

const createInitialState = () => {
  const referrer = getReferrerInfo()

  // Ignore non-existent or external referrers
  if (!referrer || !referrer.href.includes(window.location.host)) return {}

  const { pathname, query } = referrer

  // Set 'from' information according to available URL information

  if (pathname.includes('/home')) return { fromPage: 'home' }

  // /videos or /videos/ but not /videos/[id]
  if (pathname.match(/\/videos\/?$/))
    return {
      fromPage: 'classes',
      fromDetail: 'onDemand',
      classesFilter: routerQueryToQueryParams(query),
    }

  // /obe-collections/[slug] only
  if (pathname.match(/\/obe-collections\/[^/]+/))
    return { fromPage: 'obeCollections', fromDetails: pathname.split('/').slice(-1)[0] }

  // /instructors/[slug] only
  if (pathname.match(/\/instructors\/[^/]+/)) return { fromPage: 'instructors' }

  if (pathname.includes('/class-history'))
    return { fromPage: 'profile', fromDetails: 'classHistory' }

  if (pathname.includes('/collections/all'))
    return { fromPage: 'profile', fromDetails: 'allBookmarks' }

  if (pathname.includes('/collections/favorites'))
    return { fromPage: 'profile', fromDetails: 'favorites' }

  if (pathname.includes('/collections/'))
    return { fromPage: 'profile', fromDetails: 'customCollection' }

  return {}
}

// Stores information regarding last location when navigating the app
export const NavigationTrackerProvider = ({ children }) => {
  // Example "from" object structure
  // {
  //   fromPage: string (home | classes | obeCollections | instructors | programs | profile)
  //   fromDetails: string (carouselName | filterResults | collectionName | instructorID | programID | profileOrigin)
  //   classesFilter?: {
  //     filter1: string
  //     ...
  //   }
  // }
  const [navigatedFrom, setNavigatedFrom] = React.useState(createInitialState)

  return (
    <NavigationContext.Provider value={{ navigatedFrom, setNavigatedFrom }}>
      {children}
    </NavigationContext.Provider>
  )
}

export const useNavigationTracker = () => React.useContext(NavigationContext)
